import validate from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/runner/work/Hangar/Hangar/frontend/src/middleware/auth.global.ts";
import data_45global from "/home/runner/work/Hangar/Hangar/frontend/src/middleware/data.global.ts";
import settings_45global from "/home/runner/work/Hangar/Hangar/frontend/src/middleware/settings.global.ts";
import manifest_45route_45rule from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  data_45global,
  settings_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}