import payload_plugin_o5Dmrs0OlI from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_prP4uFA2bx from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zQKBdexNTB from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_c3VWaDYwrL from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_p2zA3jLXFz from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_KcFfazT3hI from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_J2pcH1IfCi from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_IPku0QLOFS from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_oZ9OcWupYR from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_kMbfRmKjQk from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.15.0_jiti@2.4.2__ior_jlfdjzq3tmgvs5dkk6lpr4xmgq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_n0SyRBjzlV from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_rollup@4_uw4z6msujf436fcvmelshjcj6q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_5hG2bPm3nW from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_rollup@4_uw4z6msujf436fcvmelshjcj6q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_tNiIFqEIaB from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_rollup@4_uw4z6msujf436fcvmelshjcj6q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/home/runner/work/Hangar/Hangar/frontend/.nuxt/unocss.mjs";
import axios_OijlQmfXsA from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/axios.ts";
import dompurify_client_EYdHkZatd0 from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/dompurify.client.ts";
import nprogress_lI8WDWlVpm from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/nprogress.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/Hangar/Hangar/frontend/src/plugins/sentry.client.ts";
import ssg_detect_U76Wo2WsyZ from "/home/runner/work/Hangar/Hangar/frontend/node_modules/.pnpm/@nuxtjs+i18n@9.2.0_@vue+compiler-dom@3.5.13_eslint@9.15.0_jiti@2.4.2__magicast@0.3.5_rollup@4_uw4z6msujf436fcvmelshjcj6q/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_o5Dmrs0OlI,
  revive_payload_client_prP4uFA2bx,
  unhead_zQKBdexNTB,
  router_c3VWaDYwrL,
  payload_client_p2zA3jLXFz,
  navigation_repaint_client_KcFfazT3hI,
  check_outdated_build_client_J2pcH1IfCi,
  chunk_reload_client_IPku0QLOFS,
  plugin_vue3_oZ9OcWupYR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kMbfRmKjQk,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_n0SyRBjzlV,
  route_locale_detect_5hG2bPm3nW,
  i18n_tNiIFqEIaB,
  unocss_MzCDxu9LMj,
  axios_OijlQmfXsA,
  dompurify_client_EYdHkZatd0,
  nprogress_lI8WDWlVpm,
  sentry_client_KAXFuL2wum,
  ssg_detect_U76Wo2WsyZ
]